<template>
  <v-container fluid>
    <v-card>
      <v-container fluid>
        <v-data-table
          :headers="headers"
          :loading="buscandoConfiguracoes"
          loading-text="Carregando as configurações..."
          :items="configuracoes"
          item-class="class"
          :footer-props="{ itemsPerPageOptions: [5, 10, 15, 30, 50], showFirstLastPage: true }"
        >
          <template #top>
            <v-container fluid class="d-flex">
              <v-toolbar-title>
                <v-icon> fab fa-paypal </v-icon>
                <span> Integração PayPal</span>
              </v-toolbar-title>
              <v-spacer />

              <v-dialog v-model="dialogInclusao" max-width="550px">
                <template #activator="{ on, attrs }">
                  <v-btn color="primary" v-bind="attrs" :disabled="!permiteAdicionar" v-on="on">
                    Adicionar
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>
                    <span class="headline"> Adicionar configuração </span>
                  </v-card-title>

                  <v-card-text>
                    <v-form ref="inclusaoForm" v-model="validFormInclusao" @submit.prevent>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-select
                              v-model="empresaConfiguracao.id"
                              :items="itensEmpresa"
                              label="Empresa"
                              required
                              :rules="[obrigatorio]"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-alert v-if="showMessageError" dense outlined type="error">
                              {{ messageError }}
                            </v-alert>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="fecharDialogInclusao">
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="green darken-1"
                      text
                      :disabled="!validFormInclusao"
                      :loading="adicionandoConfiguracao"
                      @click="adicionarConfiguracao"
                    >
                      Salvar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-container>
          </template>

          <template #[`item.empresa.documento`]="{ item }">
            {{ formataCpfCnpj(item.empresa.documento) }}
          </template>

          <template #[`item.acoes`]="{ item }">
            <div class="d-flex flex-nowrap justify-center">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon
                    class="mr-2"
                    :disabled="!permiteEditar"
                    v-on="on"
                    @click="abrirDialogConfiguracao(item)"
                  >
                    mdi-cog
                  </v-icon>
                </template>

                <span> Configurar </span>
              </v-tooltip>

              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon
                    class="mr-2"
                    :disabled="!permiteRemover"
                    v-on="on"
                    @click="abrirDialogExclusao(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>

                <span> Excluir </span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-container>
    </v-card>

    <SnackbarMessage
      :show-snack.sync="snackbar.showSnackParam"
      :snack-text="snackbar.snackTextParam"
      :snack-type="snackbar.snackTypeParam"
    />

    <v-dialog v-model="dialogExclusao" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">Excluir</v-card-title>

        <v-card-text>
          Tem certeza que deseja remover esta configuração?
          <p>O histórico de integrações também será excluído.</p>
        </v-card-text>

        <v-card-text>
          <v-alert v-if="showMessageError" dense outlined type="error">
            {{ messageError }}
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="red darken-1" text @click="fecharDialogExclusao()"> Cancelar </v-btn>
          <v-btn
            color="green darken-1"
            text
            :loading="excluindoConfiguracao"
            @click="excluirConfiguracao"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogStackTrace" scrollable>
      <v-card>
        <v-toolbar dense flat dark color="primary">
          <v-toolbar-title> Erro </v-toolbar-title>

          <v-spacer />

          <v-btn icon dark @click="dialogStackTrace = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="my-3">
          <v-progress-circular v-if="buscandoStackTrace" indeterminate color="primary" />
          {{ stackTrace }}
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfiguracao" fullscreen>
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title>
            Integração - Empresa {{ configuracaoIntegracao.empresa.nome }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon dark @click="fecharDialogConfiguracao()">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-toolbar>

        <v-container fluid>
          <v-card>
            <v-card-title> Configuração </v-card-title>
            <v-card-text>
              <v-form ref="configuracaoForm" v-model="validForm" @submit.prevent>
                <v-row justify="center">
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field
                      v-model="configuracaoIntegracao.endereco"
                      label="Endereço *"
                      required
                      :rules="[obrigatorio]"
                    />
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <v-text-field
                      v-model="configuracaoIntegracao.porta"
                      label="Porta *"
                      required
                      :rules="[obrigatorio]"
                      type="number"
                    />
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <CampoDeCnpj
                      v-model="configuracaoIntegracao.cnpj"
                      label="CNPJ Adquirente *"
                      required
                      :rules="[obrigatorio]"
                    />
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <v-text-field
                      v-model="configuracaoIntegracao.identificadorConta"
                      label="Identificador PayPal *"
                      required
                      :rules="[obrigatorio]"
                    />
                  </v-col>

                  <v-col cols="12" sm="6" md="5">
                    <v-text-field
                      v-model="configuracaoIntegracao.usuario"
                      label="Usuário *"
                      required
                      :rules="[obrigatorio]"
                    />
                  </v-col>

                  <v-col cols="12" sm="6" md="5">
                    <v-text-field
                      v-model="configuracaoIntegracao.senha"
                      label="Senha *"
                      required
                      :rules="[obrigatorio]"
                      type="password"
                    />
                  </v-col>

                  <v-col class="text-right" align-self="center">
                    <v-btn
                      class="ml-4"
                      color="primary"
                      outlined
                      type="submit"
                      :disabled="!validForm"
                      :loading="salvando"
                      @click="atualizarConfiguracao(configuracaoIntegracao)"
                    >
                      Salvar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>

          <v-card class="mt-5">
            <v-container fluid>
              <v-data-table
                :headers="headersLogs"
                :loading="buscandoLogs"
                loading-text="Carregando os logs..."
                :items="logs"
                item-class="class"
                :footer-props="{
                  itemsPerPageOptions: [5, 10, 15, 30, 50],
                  showFirstLastPage: true
                }"
              >
                <template #top>
                  <v-container fluid class="d-flex">
                    <v-toolbar-title> Logs </v-toolbar-title>

                    <v-spacer />

                    <v-tooltip left>
                      <template #activator="{ on }">
                        <v-icon v-on="on" @click="buscarLogs(configuracaoIntegracao)">
                          mdi-sync
                        </v-icon>
                      </template>

                      <span> Atualizar </span>
                    </v-tooltip>
                  </v-container>
                </template>

                <template #[`item.tipoLog`]="{ item }">
                  <strong
                    :class="
                      item.tipoLog === 'ERRO'
                        ? 'red--text'
                        : item.tipoLog === 'ATENÇÃO'
                        ? 'orange--text'
                        : 'blue--text'
                    "
                  >
                    {{ item.tipoLog }}
                  </strong>
                </template>

                <template #[`item.mensagem`]="{ item }">
                  {{ item.mensagem }}
                  <v-tooltip v-if="item.tipoLog === 'ERRO'" top>
                    <template #activator="{ on }">
                      <v-icon color="red" v-on="on" @click="buscarStackTrace(item)">
                        mdi-alert-circle-outline
                      </v-icon>
                    </template>

                    <span> Visualizar erro </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-container>
          </v-card>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { formataCpfCnpj, formatDateTimeWithSeconds } from '@/shareds/formatacoes'
import { obrigatorio } from '@/shareds/regrasForm'
import IntegracaoService from '@/services/integracao/integracao'
import EmpresaService from '@/services/organizacao/empresa'
import SnackbarMessage from '@/components/ui/SnackbarMessage'
import CampoDeCnpj from '@/components/ui/CampoDeCnpj'
import Snackbar from '@/models/ui/snackbar'
import store from '@/store'

export default {
  components: {
    SnackbarMessage,
    CampoDeCnpj
  },

  data: () => ({
    headers: [
      { text: 'Empresa', value: 'empresa.nome' },
      { text: 'CNPJ', value: 'empresa.documento' },
      { text: '', value: 'acoes', width: '1%' }
    ],
    headersLogs: [
      {
        text: 'Data Execução',
        value: 'dataHoraCriacao',
        width: '15%',
        divider: true,
        sortable: false
      },
      { text: 'Tipo', value: 'tipoLog', width: '10%', divider: true, sortable: false },
      { text: 'Mensagem', value: 'mensagem', sortable: false }
    ],
    configuracoes: [],
    logs: [],
    buscandoLogs: false,
    log: {},
    stackTrace: '',
    dialogStackTrace: false,
    buscandoStackTrace: false,
    salvando: false,
    buscandoConfiguracoes: false,
    adicionandoConfiguracao: false,
    excluindoConfiguracao: false,
    validForm: true,
    validFormInclusao: true,
    dialogConfiguracao: false,
    dialogInclusao: false,
    configuracaoIntegracao: {
      empresa: {}
    },
    empresaConfiguracao: {},
    itemConfiguracaoExclusao: {},
    indiceConfiguracaoExclusao: 0,
    dialogExclusao: false,
    snackbar: new Snackbar(),
    itensEmpresa: [],
    showMessageError: false,
    messageError: '',
    formataCpfCnpj: formataCpfCnpj,
    formatDateTimeWithSeconds: formatDateTimeWithSeconds,
    obrigatorio: obrigatorio
  }),

  computed: {
    permiteAdicionar() {
      return store.getters.verificaPermissaoAcesso('IntegracaoPayPal', 'ADICIONAR')
    },
    permiteEditar() {
      return store.getters.verificaPermissaoAcesso('IntegracaoPayPal', 'EDITAR')
    },
    permiteRemover() {
      return store.getters.verificaPermissaoAcesso('IntegracaoPayPal', 'REMOVER')
    }
  },

  created() {
    this.buscarConfiguracoes()
    this.buscarItensEmpresa()
  },

  methods: {
    buscarConfiguracoes() {
      this.buscandoConfiguracoes = true

      IntegracaoService.buscarConfiguracoesAdquirente('PAYPAL')
        .then((response) => (this.configuracoes = response))
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os permissoes.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscandoConfiguracoes = false))
    },

    buscarItensEmpresa() {
      EmpresaService.buscarTudo()
        .then((response) => {
          this.itensEmpresa = response.map((item) => ({
            text: item.nome,
            value: item.id
          }))
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os empresas.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscandoEmpresa = false))
    },

    abrirDialogConfiguracao(item) {
      this.configuracaoIntegracao = item
      this.dialogConfiguracao = true
      this.buscarLogs(item)
    },

    fecharDialogConfiguracao() {
      this.configuracaoIntegracao = { empresa: {} }
      this.logs = []
      this.$refs.configuracaoForm.resetValidation()
      this.dialogConfiguracao = false
    },

    buscarLogs(item) {
      this.logs = []
      this.buscandoLogs = true

      IntegracaoService.buscarLogs(item.id)
        .then(
          (response) =>
            (this.logs = response.map((log) => ({
              ...log,
              tipoLog:
                log.tipoLog === 'INFORMACAO'
                  ? 'INFORMAÇÃO'
                  : log.tipoLog === 'ATENCAO'
                  ? 'ATENÇÃO'
                  : log.tipoLog,
              dataHoraCriacao: formatDateTimeWithSeconds(log.dataHoraCriacao)
            })))
        )
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os logs da configuração.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscandoLogs = false))
    },

    buscarStackTrace(log) {
      this.stackTrace = ''
      this.dialogStackTrace = true
      this.log = log
      this.buscandoStackTrace = true

      IntegracaoService.buscarStackTrace(log.configuracaoIntegracao.id, log.id)
        .then((response) => (this.stackTrace = response))
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar o stackTrace do log.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscandoStackTrace = false))
    },

    adicionarConfiguracao() {
      this.adicionandoConfiguracao = true

      IntegracaoService.adicionarConfiguracaoAdquirente('PAYPAL', this.empresaConfiguracao.id)
        .then((response) => {
          this.snackbar.snackTextParam = 'Configuração adicionada com sucesso.'
          this.snackbar.snackTypeParam = 'sucess'
          this.snackbar.showSnackParam = true

          this.configuracoes.push(response)

          this.fecharDialogInclusao()
        })
        .catch((erro) => {
          this.messageError = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao adicionar a configuração.'
          }`
          this.showMessageError = true
        })
        .finally(() => (this.adicionandoConfiguracao = false))
    },

    fecharDialogInclusao() {
      this.dialogInclusao = false
      this.empresaConfiguracao = {}
      this.showMessageError = false
      this.messageError = ''
      this.$refs.inclusaoForm.resetValidation()
    },

    fecharDialogExclusao() {
      this.showMessageError = false
      this.messageError = ''
      this.dialogExclusao = false
    },

    abrirDialogExclusao(item) {
      this.indiceConfiguracaoExclusao = this.configuracoes.indexOf(item)
      this.itemConfiguracaoExclusao = this.configuracoes[this.indiceConfiguracaoExclusao]

      if (!this.itemConfiguracaoExclusao.id) return

      this.dialogExclusao = true
    },

    excluirConfiguracao() {
      this.excluindoConfiguracao = true

      IntegracaoService.excluirConfiguracao(this.itemConfiguracaoExclusao.id)
        .then(() => {
          this.fecharDialogExclusao()

          this.snackbar.snackTextParam = 'Configuração excluída com sucesso.'
          this.snackbar.snackTypeParam = 'sucess'
          this.snackbar.showSnackParam = true

          this.configuracoes.splice(this.indiceConfiguracaoExclusao, 1)
        })
        .catch((erro) => {
          this.messageError = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao excluir a configuração.'
          }`
          this.showMessageError = true
        })
        .finally(() => (this.excluindoConfiguracao = false))
    },

    atualizarConfiguracao(item) {
      this.salvando = true

      IntegracaoService.atualizar(item.id, {
        ...item,
        cnpj: item.cnpj.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '')
      })
        .then(() => {
          this.snackbar.snackTextParam = 'Configuração atualizada com sucesso.'
          this.snackbar.snackTypeParam = 'sucess'
          this.snackbar.showSnackParam = true
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao atualizar a configuração.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.salvando = false))
    }
  }
}
</script>
